<!-- Content Header (Page header) -->
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0">{{ $t("labels.dashboard") }}</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/">{{ $t("labels.home") }}</router-link>
                    </li>
                    <li class="breadcrumb-item active">Dashboard v1</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <template v-for="item in items" :key="item">
            <template v-for="node in item" :key="node">          
                <b-card bg-variant="light"  border-variant="primary" class="text-center">
                    <template #header>
                        <h6>Zone {{ node.id }}</h6>
                    </template>
                    <b-card-group>
                        <b-card  bg-variant="light" header-bg-variant="light" border-variant="light">
                            <template #header>
                                <span> {{ $t("labels.temperature") }} &degC</span>                              
                            </template>                            
                            <h1 style="color:#2E86C1;">{{ node.temperature }}</h1>
                            <b-row align-h="center">
                                <b-col cols="2" style="padding-right:0px;"><font-awesome-icon icon="fa-solid fa-caret-up" /></b-col>
                                <b-col cols="2" style="padding-left:0px;"><h5>33.5</h5></b-col>  
                            </b-row>    
                            <b-row align-h="center">
                                <b-col cols="2" style="padding-right:0px;"><font-awesome-icon icon="fa-solid fa-caret-down" /></b-col>
                                <b-col cols="2" style="padding-left:0px;"><h5>21.5</h5></b-col>  
                            </b-row>                                
                            <b-button variant="outline-primary">Reset</b-button>
                        </b-card>
                        <b-card bg-variant="light" header-bg-variant="light" border-variant="light">
                            <template #header>
                               <span> {{ $t("labels.humidity") }} %RH</span>
                            </template>                             
                            <h1 style="color:#2E86C1;">{{ node.humidity }}</h1>
                            <b-row align-h="center">
                                <b-col cols="2" style="padding-right:0px;"><font-awesome-icon icon="fa-solid fa-caret-up" /></b-col>
                                <b-col cols="2" style="padding-left:0px;"><h5>33.5</h5></b-col>  
                            </b-row>    
                            <b-row align-h="center">
                                <b-col cols="2" style="padding-right:0px;"><font-awesome-icon icon="fa-solid fa-caret-down" /></b-col>
                                <b-col cols="2" style="padding-left:0px;"><h5>21.5</h5></b-col>  
                            </b-row>                            
                            <b-button variant="outline-primary">Reset</b-button>
                        </b-card>     
                        <b-card bg-variant="light" header-bg-variant="light" border-variant="light">
                            <template #header>
                                CO2 PPM
                            </template>                                
                            <h1 style="color:#2E86C1;">{{ node.co2 }}</h1>
                            <b-row align-h="center">
                                <b-col cols="2" style="padding-right:0px;"><font-awesome-icon icon="fa-solid fa-caret-up" /></b-col>
                                <b-col cols="2" style="padding-left:0px;"><h5>33.5</h5></b-col>  
                            </b-row>    
                            <b-row align-h="center">
                                <b-col cols="2" style="padding-right:0px;"><font-awesome-icon icon="fa-solid fa-caret-down" /></b-col>
                                <b-col cols="2" style="padding-left:0px;"><h5>21.5</h5></b-col>  
                            </b-row>                            
                            <b-button variant="outline-primary">Reset</b-button>
                        </b-card>
                        <b-card bg-variant="light" header-bg-variant="light" border-variant="light">
                            <template #header>
                                <span> {{ $t("labels.luminosity") }} Lux</span> 
                            </template>                               
                            <h1 style="color:#2E86C1;">{{ node.light }}</h1>
                            <b-row align-h="center">
                                <b-col cols="2" style="padding-right:0px;"><font-awesome-icon icon="fa-solid fa-caret-up" /></b-col>
                                <b-col cols="2" style="padding-left:0px;"><h5>33.5</h5></b-col>  
                            </b-row>    
                            <b-row align-h="center">
                                <b-col cols="2" style="padding-right:0px;"><font-awesome-icon icon="fa-solid fa-caret-down" /></b-col>
                                <b-col cols="2" style="padding-left:0px;"><h5>21.5</h5></b-col>  
                            </b-row>                            
                            <b-button variant="outline-primary">Reset</b-button>
                        </b-card>                                                                   
                    </b-card-group>                              
                </b-card>
            </template>
        </template>  
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->




