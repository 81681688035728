<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Configurations</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Configurations</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Zone 1</h3>

                <div class="card-tools">
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                    >
                        <i class="fa fa-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="remove"
                        data-toggle="tooltip"
                        title="Remove"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                
                    <!-- <div> -->
                      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                        <b-form-group
                          id="input-group-1"
                          label="Temperature Setpoint:"
                          label-for="input-1"
                          description="Enter the desired temperature setpoint for the zone"
                        > 
                          <b-form-input
                            id="input-1"
                            v-model="form.tempSetpoint" 
                            type="number"
                            placeholder="Enter setpoint"
                            required
                          ></b-form-input>
                        </b-form-group>
                  
                        <b-form-group id="input-group-2" label="Sensor Name:" label-for="input-2">
                          <b-form-input
                            id="input-2"
                            v-model="form.name"
                            placeholder="Enter name"
                            required
                          ></b-form-input>
                        </b-form-group>
                  
                        <b-form-group id="input-group-3" label="Units:" label-for="input-3">
                          <b-form-select
                            id="input-3"
                            v-model="form.units"
                            :options="units"
                            required
                          ></b-form-select>
                        </b-form-group>
                  
                        <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                          <b-form-checkbox-group
                            v-model="form.checked"
                            id="checkboxes-4"
                            :aria-describedby="ariaDescribedby"
                          >
                            <b-form-checkbox value="me">Check me out</b-form-checkbox>
                            <b-form-checkbox value="that">Check that out</b-form-checkbox>
                          </b-form-checkbox-group>
                        </b-form-group>
                  
                        <b-button type="submit" variant="primary">Submit</b-button>
                        <b-button style="margin-left:10px;" type="reset" variant="danger">Reset</b-button>
                      </b-form>
                      <b-card class="mt-3" header="Form Data Result">
                        <pre class="m-0">{{ form }}</pre>
                      </b-card>
                    <!-- </div> -->
                
            </div>
            <!-- /.card-body -->
            <div class="card-footer">Footer</div>
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->



<!-- <template> -->



<!-- </template>     -->