<template src="./settings.html"></template>

<script>

  export default {
    data() {
      return {
        form: {
          tempSetpoint: '',
          name: '',
          units: null,
          checked: []
        },
        units: [{ text: 'Select One', value: null }, 'Celcius', 'Fahrenheit'],
        show: true
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.tempSetpoint = ''
        this.form.name = ''
        this.form.units = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
  }
</script>


<style>

</style>
