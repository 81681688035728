<template src="./dashboard.html"></template>
<script>
import sensorData from './data.json';

export default {
  data() {
    return {
      items: sensorData,
      visible: false,
      visible2: false
    };
  } 
};

</script>
<style src="./dashboard.scss" lang="scss"></style>

