import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "user-header bg-primary" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-body" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-4 text-center" }
const _hoisted_7 = { href: "#" }
const _hoisted_8 = { class: "col-4 text-center" }
const _hoisted_9 = { href: "#" }
const _hoisted_10 = { class: "col-4 text-center" }
const _hoisted_11 = { href: "#" }
const _hoisted_12 = { class: "user-footer" }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_app_dropdown = _resolveComponent("app-dropdown")

  return (_openBlock(), _createBlock(_component_app_dropdown, { class: "user-menu" }, {
    "dropdown-button": _withCtx(() => [
      _createElementVNode("img", {
        src: (_ctx.user && _ctx.user.picture) ? _ctx.user.picture : require('@/assets/img/default-profile.png'),
        class: "user-image img-circle elevation-2",
        alt: "User Image"
      }, null, 8, _hoisted_1)
    ]),
    "dropdown-menu": _withCtx(() => [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("img", {
          src: (_ctx.user && _ctx.user.picture) ? _ctx.user.picture : require('@/assets/img/default-profile.png'),
          class: "img-circle elevation-2",
          alt: "User Image"
        }, null, 8, _hoisted_3),
        _createElementVNode("p", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.user && _ctx.user.email), 1),
          _createElementVNode("small", null, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.memberSince")) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.readableCreatedAtDate), 1)
          ])
        ])
      ]),
      _createElementVNode("li", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t("labels.followers")), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t("labels.sales")), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("a", _hoisted_11, _toDisplayString(_ctx.$t("labels.friends")), 1)
          ])
        ])
      ]),
      _createElementVNode("li", _hoisted_12, [
        _createVNode(_component_router_link, {
          to: "/profile",
          class: "btn btn-default btn-flat",
          onClick: _cache[0] || (_cache[0] = $event => {_ctx.isDropdownOpened = false;})
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("labels.profile")), 1)
          ]),
          _: 1
        }),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.logout && _ctx.logout(...args))),
          class: "btn btn-default btn-flat float-right"
        }, _toDisplayString(_ctx.$t("labels.signOut")), 1)
      ])
    ]),
    _: 1
  }))
}